import translations from "../../translations"
import * as I18n from "../../services/i18n"

export default class RenderPlannings
  constructor: (plannings) ->
    @plannings = plannings


  show_selecteds_plannings: (plannings) ->
    cells = []
    that = @
    $.each(plannings, (index, cell_params) ->
      cell_params.selected = true
      cells.push that.mount_cell cell_params
    )
    cells

  show_planning_cell: () ->
    cells = ""
    that = @
    $.each(@plannings, (index, cell_params) ->
      cells += that.mount_cell cell_params
    )
    $('.cc-hide-list').text('').append(cells)

  mount_cell: (cell_params) ->
    has_selected_class = 'cc-planning-selected' if is_selected(cell_params)
    total_place = ' '+cell_params.quantity+' '+cell_params.unity+' a '+ cell_params.unitary
    '<div class="cc-planning-cell father-cell '+has_selected_class+'" data-servicename="'+cell_params.service+'" data-placename="'+cell_params.place+'" data-unit="'+cell_params.unity+'" data-quantity="'+cell_params.quantity+'" data-unitary="'+cell_params.unitary+'" data-total="'+cell_params.total+'" data-id-ref="cell-'+cell_params.service_id+'" data-service="'+cell_params.service_id+'" data-planning="'+cell_params.planning_id+'">'+
      '<ul>'+
        '<li class="cc-planning-place"><span class="title">'+cell_params.place+'</span></li>'+
        '<li class="cc-info-cell">'+
          '<span data-toggle="popover" data-trigger="hover" data-placement="bottom" data-html="true" data-content="' +
          "#{I18n.t('contracts.modal.selected_section.initial_date')}: "+cell_params.start_at+'<br/>'+
          "#{I18n.t('contracts.modal.selected_section.final_date')}: "+cell_params.end_at+' ">'+
          'até '+ cell_params.end_at+
          '</span>'+

          '<span data-toggle="popover" data-trigger="hover" data-placement="bottom" data-content="'+total_place+'" class="popover_selected" >'+
          cell_params.total_formated + '
          </span>'+

        '</li>'+
      '</ul>'+
    '</div>'

  is_selected = (cell_params) ->
    selecteds = get_plannings_selecteds()
    in_array = $.inArray(cell_params, selecteds)
    in_array >= 0 || cell_params.selected == true

 get_plannings_selecteds = ->
    $(".cc-planning-selected.child-cell").map( ->
      return $(this).data('planning')
     ).get();



